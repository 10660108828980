// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB-3m7CBDO6ftwoqBskm7gSqXSpSsLJftw",
  authDomain: "cloneecommerceseo.firebaseapp.com",
  projectId: "cloneecommerceseo",
  storageBucket: "cloneecommerceseo.appspot.com",
  messagingSenderId: "587422072615",
  appId: "1:587422072615:web:7e6c68621bcac6a7de3302"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;